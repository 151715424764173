
const splashLogo = require('./assets/logo2.png');

async function main() {
  const element = document.getElementById('splash');

  if (element == null) {
    throw new Error("Could not get element with id='splash'");
  }
  const img = document.createElement('img');
  const div = document.createElement('div');
  div.style['background-color'] = '#03c991';
  div.style.width = '100vw';
  div.style.height = '100vh';
  div.style.top = '0';
  div.style.left = '0';
  div.style.display = 'flex';
  div.style['justify-content'] = 'center'; /* align horizontal */
  div.style['align-items'] = 'center'; /* align vertical  */

  div.style['z-index'] = '10000';
  div.style.position = 'absolute'; /* align vertical  */

  img.style['max-width'] = '50vw';
  img.src = splashLogo;

  element.appendChild(div);
  div.appendChild(img);

  // webpack code splitting: https://webpack.js.org/guides/code-splitting/

  await import(/* webpackChunkName: 'react-dom', webpackPreload: true */'react-dom');
  await import(/* webpackChunkName: 'firebase-auth', webpackPreload: true */'firebase/auth');
  await import(/* webpackChunkName: 'firestore', webpackPreload: true */'firebase/firestore');
  await import(/* webpackChunkName: 'main', webpackPrefetch: true */'./index');
}


main();
